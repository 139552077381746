var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "other-header-top" }, [
    _c(
      "div",
      { staticClass: "container df jc-sb ai-c" },
      [
        _c(
          "div",
          { staticClass: "p-t-10 p-b-10" },
          [
            _c("Icon", {
              attrs: {
                name: "LogoPortTranzit",
                width: "285px",
                height: "auto",
              },
            }),
          ],
          1
        ),
        !_vm.isSuperAdmin
          ? _c(
              "el-menu",
              {
                staticClass: "other-header-top__navigation",
                attrs: { mode: "horizontal" },
              },
              [
                _c(
                  "el-menu-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.goToQuotas.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("Icon", { attrs: { name: "IconQuotas" } }),
                    _vm._v(" Квоты "),
                  ],
                  1
                ),
                !_vm.isSupervisor
                  ? _c(
                      "el-menu-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goToMainPageUser.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("Icon", {
                          attrs: {
                            name: _vm.mainPageParams.menuIcon || "IconControl",
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isExporter ? "Поставщики" : "Управление"
                            ) +
                            " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isShowBlacklistPage
                  ? _c(
                      "el-menu-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goToBlacklist.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("Icon", { attrs: { name: "IconList" } }),
                        _vm._v(" Черный список "),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isExporter
                  ? _c(
                      "el-menu-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goToProfile.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("Icon", { attrs: { name: "IconProfile" } }),
                        _vm._v(" Профиль "),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-menu-item",
                  { on: { click: _vm.logout } },
                  [
                    _c("Icon", {
                      attrs: {
                        name: "IconGoToTerminal",
                        width: "18px",
                        height: "18px",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    }),
                    _vm._v(" Выход "),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.isSuperAdmin
          ? _c("Icon", {
              staticStyle: { cursor: "pointer" },
              attrs: { name: "IconMenuLine", width: "44px", height: "56px" },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleOpenMobileMenu.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }