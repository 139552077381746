<template>
  <div class="other-header-top">
    <div class="container df jc-sb ai-c">
      <div class="p-t-10 p-b-10">
        <Icon name="LogoPortTranzit" width="285px" height="auto" />
      </div>

      <el-menu
        v-if="!isSuperAdmin"
        mode="horizontal"
        class="other-header-top__navigation"
      >
        <el-menu-item @click.native="goToQuotas">
          <Icon :name="'IconQuotas'" />
          Квоты
        </el-menu-item>
        <el-menu-item v-if="!isSupervisor" @click.native="goToMainPageUser">
          <Icon :name="mainPageParams.menuIcon || 'IconControl'" />
          {{ isExporter ? 'Поставщики' : 'Управление' }}
        </el-menu-item>

        <el-menu-item v-if="isShowBlacklistPage" @click.native="goToBlacklist">
          <Icon name="IconList" />
          Черный список
        </el-menu-item>

        <el-menu-item v-if="!isExporter" @click.native="goToProfile">
          <Icon name="IconProfile" />
          Профиль
        </el-menu-item>

        <el-menu-item @click="logout">
          <Icon
            name="IconGoToTerminal"
            width="18px"
            height="18px"
            @click.native="logout"
          />
          Выход
        </el-menu-item>
      </el-menu>

      <Icon
        v-if="isSuperAdmin"
        name="IconMenuLine"
        width="44px"
        height="56px"
        style="cursor: pointer"
        @click.native="handleOpenMobileMenu"
      />
    </div>
  </div>
</template>

<script>
import {
  AUTH_LOGOUT,
  GET_IS_INCOMPLETE_PARKING,
  SET_DRAWER,
} from '@/store/actions'
import { MAIN_MENU } from '@/constants/drawers'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { navigationRootList } from '@/constants/navigaiton'
import Icon from '@/UI/icon/Icon'

export default {
  name: 'OtherHeaderTop',
  components: { Icon },
  props: {
    isShowProfilePage: {
      type: Boolean,
      default: false,
    },
    isShowBlacklistPage: {
      type: Boolean,
      default: false,
    },
    mainPageParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      hideParkmanPage: GET_IS_INCOMPLETE_PARKING,
    }),
    navigationList() {
      if (!this.hideParkmanPage) {
        return navigationRootList
      }
      let parkmanIndex = navigationRootList.findIndex(
        item => item.name === 'Parkman',
      )
      let supervisorIndex = navigationRootList.findIndex(
        item => item.name === 'Supervisor',
      )
      let list = navigationRootList.slice()

      list.splice(parkmanIndex, 1)
      list.splice(supervisorIndex, 1)

      return list
    },
  },
  methods: {
    ...mapActions({ logout: AUTH_LOGOUT }),
    ...mapMutations({ setDrawer: SET_DRAWER }),

    goToProfile() {
      this.$emit('goToProfile')
    },
    goToQuotas() {
      this.$emit('goToQuotas')
    },
    goToMainPageUser() {
      this.$emit('goToMainPageUser')
    },
    goToBlacklist() {
      this.$emit('goToBlacklist')
    },
    handleOpenMobileMenu() {
      this.setDrawer({
        name: MAIN_MENU,
        visible: true,
        data: { items: this.navigationList },
      })
    },
  },
}
</script>

<style lang="sass">
#app
  .other-header-top
    background: $color-blue-primary
    min-height: 60px

    &__navigation
      display: flex
      align-items: center
      justify-content: flex-end
      border: none
      background: transparent

      .el-menu-item
        color: $color-white
        display: flex
        align-items: center
        font-size: 16px
        font-weight: bold
        margin: 0
        padding: 0 32px

        svg
          margin-right: 5px

        &:hover,
        &:focus,
        &.is-active
          background-color: #50B1DB

      .el-menu-item + .el-menu-item
        border-left: 1px solid rgba(255, 255, 255, 0.3)
</style>
