import { render, staticRenderFns } from "./OtherHeaderTop.vue?vue&type=template&id=161f9485&"
import script from "./OtherHeaderTop.vue?vue&type=script&lang=js&"
export * from "./OtherHeaderTop.vue?vue&type=script&lang=js&"
import style0 from "./OtherHeaderTop.vue?vue&type=style&index=0&id=161f9485&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('161f9485')) {
      api.createRecord('161f9485', component.options)
    } else {
      api.reload('161f9485', component.options)
    }
    module.hot.accept("./OtherHeaderTop.vue?vue&type=template&id=161f9485&", function () {
      api.rerender('161f9485', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layouts/other/other-header/other-header-top/OtherHeaderTop.vue"
export default component.exports